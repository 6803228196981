export class ContactUsModel {
  public title: string = ""
  public first_name: string = ""
  public last_name: string = ""
  public first_name_kana: string = ""
  public last_name_kana: string = ""
  public order_no: string = ""
  public phone_no: string = ""
  public email: string = ""
  public email_confirmation: string = ""
  public is_contact_by_phone: number = 0
  public content: string = ""

  constructor(init?: ContactUsModel) {
    Object.assign(this, init)
  }
}
