

























































































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import { Validator, ValidationProvider, ValidationObserver } from 'vee-validate'
import LayoutSideBar from '@/layouts/LayoutSideBar.vue'
import ContactUsService from '@/services/ContactUsService'
import { ContactUsModel } from '@/models/ContactUsModel'

const dict = {
  custom: {
    title: {
      required: () => '「件名（タイトル）」を入力してください。'
    },
    orderNumber: {
      regex: () => '注文番号が無効です。'
    },
    firstName: {
      required: () => '「姓」を入力してください。',
      regex: () => '数字記号以外で入力してください。'
    },
    lastName: {
      required: () => '「名」を入力してください。',
      regex: () => '数字記号以外で入力してください。'
    },
    firstNameKana: {
      required: () => '「せい」を入力してください。',
      regex: () => '「せい」を全てひらがなで入力してください。'
    },
    lastNameKana: {
      required: () => '「めい」を入力してください。',
      regex: () => '「めい」を全てひらがなで入力してください。'
    },
    email: {
      required: () => '「メールアドレス」を入力してください。',
      email: () => '「メールアドレス」を正しく入力してください。'
    },
    emailConfirmation: {
      required: () =>
        '「メールアドレス」「メールアドレス（確認用）」で異なっているようです。ご確認ください。',
      confirmed: () =>
        '「メールアドレス」「メールアドレス（確認用）」で異なっているようです。ご確認ください。'
    },
    phoneNo: {
      required: () => '携帯電話番号を入力してください。',
      regex: () => '電話番号は半角数字でなければなりません。',
      min: () => '「電話番号」を半角英数10~11桁で入力してください。',
      max: () => '「電話番号」を半角英数10~11桁で入力してください。'
    },
    content: {
      required: () => 'お問合せいただく「内容」を入力してください。'
    }
  }
}
Validator.localize('jp', dict)

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
    LayoutSideBar
  }
})
export default class ContactUs extends Vue {
  public pattern: string = `^[a-zA-Z0-9ｧ-ﾝﾞﾟ]+$`
  public patternNumber: string = `^(\\d)+$`

  private contactInfo = new ContactUsModel()

  sendContactInfo() {
    ContactUsService.sendContactInfo(this.contactInfo).then(
      (response: any) => {
        if (response) {
          this.$router.push({
            name: 'inquiry-result',
            query: { status: 'success' }
          })
        }
      },
      (error: any) => {
        if (error.response.status === 429) {
          localStorage.clear()
          this.$bvModal.show(`too-many-request`)
        } else {
          this.$router.push({
            name: 'inquiry-result',
            query: { status: 'error' }
          })
        }
      }
    )
  }
}
