import Const from '../constants'
import { ContactUsModel } from '../models/ContactUsModel'
import axiosApiInstance from '../interceptors'

class ContactUsService {
  sendContactInfo(data: ContactUsModel) {
    let items: any = {
      title: data.title,
      first_name: data.first_name,
      last_name: data.last_name,
      first_name_kana: data.first_name_kana,
      last_name_kana: data.last_name_kana,
      phone_no: data.phone_no,
      email: data.email,
      email_confirmation: data.email_confirmation,
      is_contact_by_phone: data.is_contact_by_phone,
      content: data.content,
    }
    if (data.order_no !== '') {
      items.order_no = data.order_no
    }
    if (data.last_name !== '') {
      items.last_name = data.last_name
    }
    if (data.last_name_kana !== '') {
      items.last_name_kana = data.last_name_kana
    }
    return axiosApiInstance.post(Const.api_url + 'dashboard/contact', {
      title: data.title,
      first_name: data.first_name,
      first_name_kana: data.first_name_kana,
      last_name: data.last_name,
      last_name_kana: data.last_name_kana,
      phone_no: data.phone_no,
      email: data.email,
      email_confirmation: data.email_confirmation,
      is_contact_by_phone: data.is_contact_by_phone,
      content: data.content,
    })
  }
}

export default new ContactUsService()
